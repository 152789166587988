.navigation-wrapper {
    position: sticky !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 999;
    box-shadow: var(--shadow);
}

.navigation-top {
    padding-top: 20px
}

.navigation-wrapper > .container {
    max-width: calc(var(--container) - 2.5rem);
    padding-right: 0rem;
    padding-left: 0rem;
}

.rw-nav > .rw-inner-nav {
    max-width: calc(var(--container) - 2.5rem);
    width: 100%;
    padding-right: 0rem;
    padding-left: 0rem;
    margin-right: auto;
    margin-left: auto;
    overflow: none;
    padding-top: 1rem;
    padding-bottom: 1.25rem;
    transition: 200ms padding ease;
}

.navbar-nav a.nav-link, .navbar-nav .nav-link {
    color: var(--dark-tone) !important;
    transition: all 200ms;
    font-size: 15px;
    font-weight: 500;
    padding: 0.25rem 0.75rem !important;
}

.navbar-nav a.nav-link:hover, .navbar-nav .show>a.nav-link {
    background: rgba(0,0,0,0.03);
}

.navbar-nav .dropdown-toggle.nav-link {
    color: #3d3d3d;
    transition: all 200ms;
    font-size: 15px;
    font-weight: 600;
    border-radius: 3px;
    padding: 0.25rem 0.75rem;
}

.navbar-nav .dropdown-item {
    font-size: 15px;
    font-weight: 600;
    border-radius: 3px;
}
.navbar-nav .dropdown-item:hover {
    background: rgba(0,0,0,0.03);
}

.navbar-nav .dropdown-menu {
    padding: 0.5rem;
    border: none !important;
}

.navbar-nav .dropdown-menu .dropdown-item > div {
    font-weight: 400;
    font-size: 14px;
    color: #00000099;
    margin-top: -2px;
}

.dropdown-arrow {
    position: relative;
    font-size: 0.6rem;
    margin-left: 6px;
    transition: transform 150ms;
}

.dropdown-arrow.rotate {
    transform: rotate(180deg);
}

.dropdown-active, .dropdown-active:hover {
    background-color: rgb(215, 225, 252) !important;
}

.nav-base {
    position: fixed;
    top: 0px;
    z-index: 999;
    background-color: blue;
}


.nav-subtext {
    font-size: 14px;
    font-weight: 400;
    margin-top: -2px;
    color: #00000099;
    overflow-wrap: break-word !important;
}

.top-banner {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.8;
}

.vertical-hr {
    padding: 0.75rem 0;
    background-color: var(--border);
    width: 1px;
    margin: 0 0.5rem;
}

.badge-primary-light {
    background-color: #2936a31a;
    color: #16184B;
    border-radius: 100px;
    text-transform: none;
    font-size: 14px;
    font-weight: 300;
    padding: 8px 16px;
    transition: none !important;
}

.badge-primary-light:hover {
    background-color: #2936A3;
    color: #fff;
}

.navigation-top .nav-link {
    color: #3d3d3d;
    transition: all 200ms;
    font-size: 15px;
    font-weight: 600;
    padding: 0rem 0.75rem !important;
}

@media screen and (max-width: 992px) {
    .mega-drop {
        position: relative;
        padding: 1rem;
        top: 0;
        border: none !important;
        box-shadow: none !important;
    }
    .rw-nav {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .rw-nav .navbar-collapse {
        max-height: 80vh;
        overflow: scroll;
        border-bottom: 1px solid var(--light);
    }
    .navbar-nav>.nav-link {
        padding: 12px 0.75rem !important;
    }
    .dropdown-top a.dropdown-item, .dropdown-top .dropdown-item.active {
        width: 100% !important;
    }
}

.navbar-toggler, .navbar-toggler:focus {
    border: 2px solid var(--primary-dark) !important;
}

.navbar-toggler.collapsed, .navbar-toggler.collapsed:focus {
    border: 2px solid var(--light-gray) !important;
    outline: 0 !important;
}

.ross-nav {
    position: sticky;
    background-color: white;
    top: 0;
    padding: 0.5rem 0;
    z-index: 9999;
}

.ross-nav-inner {
    display: flex;
    justify-content: space-between;
}

.ross-nav-nav {
    display: flex;
}

.ross-nav-collapse {
    display: flex;
}

.ross-nav-collapse-btn {
    display: none;
}

@media screen and (max-width: 991px) {
    .ross-nav-collapse {
        display: block;
        position: absolute !important;
        left: 0;
        right: 0;
        background-color: #fff;
        height: 0px;
        overflow: hidden;
        top: 52px;
        transition: all 200ms;
    }
    .ross-nav-nav {
        display: block;
    }
    .ross-nav-collapse.open {
        display: block;
        height: auto;
        top: 52px;
        transition: all 200ms;
    }
    .ross-nav-collapse-btn {
        display: block;
    }
    .ross-nav-inner {
        position: relative;
        width: 100%;
    }
}



@media screen and (max-width: 991px) {

    .hide-mobile {
        display: none;
    }

    .rw-nav {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .navbar-nav .dropdown-menu {
        box-shadow: none;
        padding: 0.75rem;
        padding-bottom: 1.5rem;
    }

    .navbar-nav .dropdown-item {
        padding: 0;
        padding-bottom: 0.75rem;
    }

    .navbar-nav .nav-link:not(.dropdown-toggle.nav-link) {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0;
    }

    .navbar-nav .dropdown-toggle.nav-link, .navbar-nav .nav-link {
        font-size: 18px !important;
    }

    .navbar-nav .dropdown {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    .navbar-nav .dropdown-toggle.nav-link {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        border-radius: 0;
    }

    .navbar-nav .dropdown-toggle.nav-link:hover, .navbar-nav .nav-link:hover:not(.dropdown-toggle.nav-link), .navbar-nav a.nav-link:hover, .navbar-nav .show>a.nav-link, .navbar-nav .dropdown-item:hover {
        background:transparent;
    }
}