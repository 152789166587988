.footer-section {
  background-color: var(--light-tone);
  border-top: 1px solid var(--border);
  padding: 0px;
}

.footer-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 72px;
}

.footer-row {
  padding: 48px 0px;
}

.footer-link-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.footer-link {
  font-size: 13px;
  font-weight: 400;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-social-list {
  display: flex;
  gap: 12px;
}

.footer-social-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: var(--radius-pill);
  background-color: var(--dark);
}

.footer-social-circle img {
  width: 20px;
  max-height: 20px;
}

.footer-title-text {
  color: #222;
  font-size: 12px;
  line-height: 1.2em;
  padding-bottom: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.footer-bottom {
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom-link {
  font-size: 12px;
  margin-right: 1rem;
}

.feature-text {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
}

.flex-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.flex-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

a.flex-list-item {
  text-decoration: underline;
}

.widget-zone {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  background-color: var(--mid-tone);
  padding: 36px;
  border-radius: var(--radius-medium);
}

.widget {
  background-color: #fff;
  border-radius: var(--radius-small);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.widget.restricted-width {
  max-width: 310px;
}

.bold-text {
  font-weight: 600;
}

.home-hero {
  padding-top: calc(5rem + 60px);
  padding-bottom: 5rem;
  background-color: var(--light-blue);
}


.bg-light-orange {
  background-color: var(--warning-light);
}

.oversize-image {
  position: relative;
  height: auto;
  width: 140%;
}

.badge-link {
  color: var(--dark);
  background: rgba(0, 0, 0, 0.05);
  padding: 0px 6px;
  margin: 0px;
  border-radius: 4px;
  display: inline;
  cursor: pointer;
  font-weight: 600;
}

.badge-link:hover {
  color: var(--primary);
  background-color: var(--primary-soft);
}

.pre-text {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--primary);
  text-transform: uppercase;
  line-height: 2;
  margin-bottom: 10px;
}
.grey-hero {
  position: relative;
  top: -1rem;
  z-index: 0;
}

.feature-img {
  max-height: 260px;
  object-fit: cover;
}

ul {
  padding-left: 20px !important;
}

ul ::marker {
  padding-right: 2rem !important;
}

li {
  margin-bottom: 25px;
}

.bg-image-pills {
  background-image: url(./assets/svg/pattern-header-1.svg);
  background-position: center center;
  background-size: 120%;
}

.color-block-hero {
  background-image: url(./assets/svg/pattern-header-2.svg);
  background-position: center center;
  background-size: 1700px;
  background-repeat: no-repeat;
}

.color-block-hero-reversed {
  background-image: url(./assets/svg/pattern-header-4.svg);
  background-position: center center;
  background-size: 1700px;
  background-repeat: no-repeat;
}

.grey-block-hero {
  background-image: url(./assets/svg/pattern-header-3.svg);
  background-position: center center;
  background-size: 1700px;
  background-repeat: no-repeat;
}

.industry-hero-wrapper {
  position: relative;
}

.industry-hero-image {
  position: absolute;
  top: -1rem;
  right: -15rem;
  border-radius: 1rem;
  width: 70%;
  height: 80%;
  object-fit: cover;
  box-shadow:         0px 4px 18px 6px rgba(0, 0, 0, 0.205);
}

.timeline-start {
  position: relative;
  border-left: 2px solid var(--indigo);
  padding-left: 5rem;
  padding-bottom: 8rem;
}

.timeline-start::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: -25px;
  background-color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 2px solid var(--indigo);
}

.sd-mobile-hero {
  max-width: 100%;
  height: 500px;
  margin: 0rem auto;
}

.timeline-middle {
  position: relative;
  border-right: 2px solid var(--indigo);
  border-top: 2px solid var(--indigo);
  border-bottom: 2px solid var(--indigo);
  padding-right: 5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.timeline-middle::before {
  content: '';
  display: inline-block;
  position: absolute;
  right: -25px;
  background-color: var(--light-blue);
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 2px solid var(--indigo);
}

.timeline-end {
  position: relative;
  border-left: 2px solid var(--indigo);
  padding-left: 5rem;
  padding-bottom: 5rem;
  padding-top: 8rem
}

.timeline-end::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: -25px;
  background-color: #fff;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  border: 2px solid var(--indigo);
}

.loader-wrapper {
  position: relative;
}

.loader-main {
  position: absolute;
 height: 2rem;
 width: 2rem;
 border-radius: 50%;
 z-index: 999;
}

.loader-background {
  position: absolute;
 border: 2px solid rgba(199, 199, 199, 0.589);
 height: 2rem;
 width: 2rem;
 border-radius: 50%;
}

.inline-icon {
  position: relative;
  top: -2px;
  margin-right: 1rem;
}

.crisp-image {
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}

.learn-more {
  color: var(--primary);
  font-size: 0.88rem !important;
  font-weight: 600;
}

.learn-more > svg {
  position: relative;
  top: -1px;
  left: 3px;
  font-size: 0.6rem;
}

.learn-more:hover {
  color: var(--primary-dark);
}

.text-decoration-none {
  text-decoration: none !important;
}

.nav-title {
    margin-bottom: 10px;
    color: #283c55;
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
    text-transform: uppercase;
}
@media screen and (max-width: 991px) {
  .industry-hero-image {
    display: none;
  }
  .nav-base {
    position: relative;
  }
  .oversize-image {
    position: relative;
    height: auto;
    width: 100%;
  }

}

.step {
  position: relative;
  list-style: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-right: -.9375rem;
  margin-left: -.9375rem;
}

.step .step-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: .9375rem;
  padding-left: .9375rem;
  margin-bottom: 1.5rem;
}

.step .step-icon::after {
  position: absolute;
  top: 3.59375rem;
  left: 1.5625rem;
  height: calc(100% - 2.65625rem);
  border-left: .125rem solid #e7eaf3;
  content: "";
}

.step .step-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.step-icon-soft-primary {
  color: #377dff;
  background-color: rgba(55,125,255,.1);
}

.step.step-dashed .step-icon::after {
  border-left-style: dashed;
}

.step-icon-xs .step-icon::after, .step-icon-xs.step-icon::after {
  top: 2.21875rem;
  left: .8125rem;
  width: 1.0625rem;
  height: calc(100% - 1.28125rem);
}

.step .step-content-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.step .step-content {
  -ms-flex: 1;
  flex: 1;
}

.step-icon-xs .step-icon, .step-icon-xs.step-icon {
  font-size: .75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.muted-card-link {
  color: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  border: 1px solid transparent;
  border-radius: var(--rounded);
  transition: all 200ms;
  margin-bottom: 2rem;
}

.muted-card-link:hover {
  color: #757575 !important;
  border: 1px solid rgb(237, 237, 237);
  box-shadow: rgb(202 202 202 / 14%) 0px 0px 0px 6px;
}

a.blog-nav {
  font-size: 1rem !important;
  width: 100%;
  background-color: transparent !important;
  color: var(--gray) !important;
  border: none !important;
  border-bottom: 3px solid transparent !important;
  border-radius: 0 !important;
}

a.blog-nav:hover, .blog-container a.blog-nav.active {
  width: 100%;
  background-color: transparent !important;
  color: var(--primary) !important;
  border: none !important;
  border-bottom: 3px solid var(--primary) !important;
}

.accordion-btn {
  width: 100%;
  text-align: left;
  padding: 1.5rem 0rem;
  background-color: transparent !important;
  border: none !important;
  color: var(--dark) !important;
}

.accordion-btn:hover, .accordion-btn:focus {
  color: var(--primary) !important;
}

.play-button {
  height: 75px;
  width: 75px;
  color: #fff;
  background-color: var(--primary);
  position: absolute;
  cursor: pointer;
  z-index: 1;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: 0px 0px 0px 10px rgba(0, 105, 229,0.3);
  transition: all 150ms;
}

.play-button:hover {
  height: 80px;
  width: 80px;
  font-size: 4rem !important;
  box-shadow: 0px 0px 0px 12px rgba(0, 105, 229,0.3);

}

.pricing-header {
  position: sticky;
  top: 40px;
  z-index: 1;
  margin-bottom: -2rem;
}

.plan-checklist-box {
  background-color: #fff;
  border-radius: var(--radius-small);
  padding: 24px;
}

.pricing-header-card {
  background-color: #fff;
  padding: 1rem 2rem !important;
  box-shadow: 0 8px 40px rgba(36,51,81,.08), 0 4px 12px rgba(36,51,81,.02);
  border-radius: 0.25rem;
}

.pricing-lead {
  padding: 0.75rem calc(2rem - 3px);
  background-color: var(--sky-blue);
  border-left: 3px solid rgb(160, 193, 255);
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
  margin: 1rem 0;
}

.icon {
  display: flex;
  margin: 0 auto;
  background-color: var(--dark);
  color: #fff;
  width: 48px !important;
  height: 48px !important;
  border-radius: 1.25rem;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.icon.round {
  width: 56px !important;
  height: 56px !important;
  font-size: 1.5rem;
  border-radius: 50rem !important;
}

.fit-frame {
  width: 100%;
  max-height: 30%;
  object-fit: cover;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > img { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  width: 100%;
  margin-bottom: 30px;
}


.slick-title {
  cursor: pointer;
  color: var(--dark);
  font-weight: 600;
  font-size: 15px !important;
}

.circle-check {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 0.6rem;
  height: 24px;
  width: 24px;
  background-color: var(--primary);
  border-radius: 100px;
  margin-right: 1rem;
  background-image: url("./assets/white-check.svg");
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}


.loader-image {
  filter: blur(1.5rem);
}

.wave-divider {
  bottom: 0px;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 0;
}

.force-front {
  position: relative;
  z-index: 1;
}



.fade-bottom {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30%;
  background: linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
}

.fade-left {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  top: 0;
  width: 40%;
  background: linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);
}

.hero-image-right {
  position: absolute;
  top: -20% !important;
  left: 0;
  width: 150%;
}

.slick-dropdown > .slick-menu.closed {
  display: none !important;
}

.slick-top {
  padding: 1rem .625rem;
}

svg.slick-chevron {
  position: relative;
  font-size: 0.5rem;
  margin-left: 0.2rem;
  transition: all 150ms;
}

svg.slick-chevron.open {
  transform: rotate(180deg);
}

.navbar-nav:hover .slick-dropdown {
  opacity: 1;
}

.slick-dropdown:hover {
  opacity: 1 !important;
}

.onboarding-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: var(--dark);
  border-radius: 5px;
  background: #fff;
  font-size: 1rem;
  margin: 0.5rem 0;
  box-shadow: inset 0 0 1.2px 0 rgb(24 51 47 / 40%);
}

.onboarding-link .number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary);
  background: var(--primary-light);
  height: 24px;
  width: 24px;
  border-radius: 50px;
  padding: 12px;
  margin-right: 16px;
}

.onboarding-link .links {
  display: flex;
  align-items: center;
}

.onboarding-link .right-arrow {
  position: relative;
  color: var(--primary);
  font-size: 0.8rem;
  transition: all 200ms;
  left: -9px;
}

.onboarding-link:hover .right-arrow {
  transform: translateX(9px);
}

.onboarding-link:hover {
  box-shadow: inset 0 0 1px 0 rgb(24 51 47 / 40%), 0 2px 2px 0 rgb(24 51 47 / 3%), 0 2px 2px 0 rgb(24 51 47 / 3%), 0 4px 4px 0 rgb(24 51 47 / 3%), 0 4px 4px 0 rgb(24 51 47 / 3%), 0 4px 4px 0 rgb(24 51 47 / 3%);
  transition: box-shadow .2s ease-in-out;
  cursor: pointer;
  color: var(--dark);
}

.force-image-fit img {
  max-width: 100%;
}

.icon-left {
  position: relative;
  font-size: 0.8rem;
  top: -2px;
  margin-right: 0.5rem;
}

.form-optional {
  position: absolute;
  font-size: 10px;
  margin-top: 9px;
  top: 50%;
  right: 2rem;
  pointer-events: none;
  text-transform: uppercase;
  color: #9D9D9D;
}

.step-container {
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.step-indicator {
  position: relative;
  color: var(--gray);
  width: 100%;
  font-weight: 500;
  padding-top: 1rem;
  font-size: 14px;
  text-align: left;
  border-top: 4px solid var(--border);
}

.step-inner-top {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;

}

.stepIndicatorStepActive {
  border-color: var(--primary);
  color: #000;
}

.stepIndicatorStepActive .step-inner-top {
  color: var(--primary);
}

.form-label {
  font-weight: 500;
  margin-top: 0.75rem;
  font-size: 0.8.75rem;
  line-height: 1.5rem;
  color: var(--dark);
}

.form-label > label {
  color: var(--dark);
}

.pricingPlanSelector {
  width: 100%;
  display: flex;
  gap: 10px;
}

.pricingPlanOption {
  position: relative;
  border: 1px solid var(--border);
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: var(--rounded-lg);
  padding: 1rem;
  text-align: left;
  cursor: pointer;
}


.pricingPlanOptionActive {
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, var(--primary) 0px 0px 0px 2px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-color: var(--primary);
}

.pricingPlanDescription {
  position: relative;
  top: -2px;
  font-size: 0.8rem;
}
.orderFormNav {
  display: flex;
  gap: 10px;
}
.purchaseAgreement {
  padding: 5px;
  font-size: 0.8rem;
}
.purchaseSummary {
  font-size: 0.8rem;
}

.plan-circle-check {
  position:absolute;
  top: 1rem;
  right: 1.5rem;
  background-color: var(--primary);
  color: #ffffff;
  height: 1.25rem;
  width: 1.25rem;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.pricingPlanOptionActive .plan-circle-check {
  display: flex;
}

.server-terms {
  max-height: 300px;
  background-color: var(--light);
  padding: 1.25rem;
  border: 1px solid var(--border);
  border-radius: var(--rounded-lg);
  overflow-y: scroll;
  overflow: -moz-scrollbars-vertical; 
}

.server-terms p, .server-terms ol, .server-terms ul {
  font-size: 0.8rem;
  line-height: 1rem !important;
  color:rgba(0, 0, 0, 0.5);
}

.server-terms li, .server-terms p{
  margin-bottom: 0.75rem;
}

.server-terms h3 {
  font-size: 1rem;
  color:rgba(0, 0, 0, 0.65);
}

.accordion__item {
  font-size: 17px;
  font-weight: 600;
  background-color: transparent;
  border-top: 1px solid var(--border);
}

.accordion__item:last-child {
  border-bottom: 1px solid var(--border);
}

.accordion__button {
  padding: 17px 0;
}


.accordion__panel, .accordion__panel p {
  font-size: 17px;
  color: #00000099;
  line-height: 1.55 !important;
  font-weight: 430;
}

.accordion__heading {
  padding-right: 2rem;
}

.accordion__item {
  position: relative;
}

.accordion__plus {
  position: absolute;
  top: 1.25rem;
  right: 0;
  transform: rotate(135deg);
  font-size: 1.15rem;
  transition: all 500ms;
}

.accordion__button[aria-expanded='true'] .accordion__plus {
  transform: rotate(0deg);
}

.profile-picture {
  width: 6rem;
  height: 6rem;
  margin-bottom: 1rem;
  object-fit: cover;
  border-radius: 100px;
}

.horizontal-slider {
  position: relative;
  margin-top: 4rem;
  width: 100%;
  height: 50px;
}

@media screen and (max-width: 991px) {
  .horizontal-slider {
    position: relative;
    margin-top: 4rem;
    width: 80%;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}

.slider-thumb > span {
  position: absolute;
  top: -3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 2rem;
  background-color: var(--dark);
  border-radius: var(--rounded);
  color: #fff;
  font-weight: 600;
  text-align: center;
  outline: none;
  z-index: 999 !important;
  font-size: 1.1rem;
}

.slider-thumb > span::after {
  content: '';
  z-index: 0 !important;
  position: absolute;
  bottom: -6px;
  border-radius: 3px;
  border: 7px solid var(--dark);
  transform: rotate(45deg);
  background-color: var(--dark);
}

.slider-thumb, .slider-thumb:focus, .slider-thumb:focus-visible {
  content: '';
  border: 4px solid #fff;
  position: relative;
  top: 8px;
  margin-left: 12px;
  outline: none;
  height: 2rem;
  width: 2rem;
  background-color: var(--primary-medium);
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: #fff;
  box-shadow: var(--shadow);
}

.slider-thumb::after {

}

.slider-track-0 {
  border-radius: 50px;
  background-color: var(--primary-medium);
  border: 1px solid var(--primary-medium);
}

.slider-track-1 {
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 50px;
}

.horizontal-slider .slider-track {
  top: 20px;
  height: 10px;
}

.calendly-inline-widget{
  min-width: 300px;
  height: 700px;
}

.expand-item {
  position: relative;
  display: grid;
  user-select: none;
  cursor: pointer;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: var(--rounded);
  margin-bottom: 1rem;
}


.expand-title {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
  color: var(--dark-tone)
}

.expand-body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 400ms ease-in-out;
}

.expand-body.expanded {
  grid-template-rows: 1fr;
}

.expand-body > div {
  overflow: hidden;
  margin-top: 0;
  opacity: 0;
  transition: all 400ms ease-in-out;
}

.expand-body.expanded > div {
  margin-top: 1rem;
  opacity: 1;
}


.expand-body > svg {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  transform: rotate(45deg);
  transition: all 200ms;
}

.expand-body.expanded > svg {
  transform: rotate(0deg);
}
